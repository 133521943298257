<template>
  <div class="container" id="confirmModelTips">
    <a-modal
      :visible="confirmModal"
      :getContainer="() => rootContainer"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      @cancel="cancel"
    >
      <p class="cc_hd">提示</p>
      <p class="confirm_tt"><a-icon type="exclamation-circle" class="icon_waring" style=""/>因域名已迁移，请使用以下地址访问企业服务平台</p>
      <template v-for="(item, index) in arr">
        <div :key="index">
          <!-- <p class="name">{{item.name}}: <a target="_self" :href="item.domain" class="url">{{item.domain}}</a></p> -->
          <a target="_self" :href="item.domain" class="url">{{item.domain}}</a>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    confirmModal: {
      type: Boolean,
      default: false,
    },
    arr: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
    }
  },
  computed: {
    // 指定Dialog的挂载点
    rootContainer() {
      return document.querySelector('#confirmModelTips')
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    cancel() {
      this.$emit('handleClose')
    },
  },
}
</script>
<style lang="scss" scoped>
.container{
  /deep/ .cc_hd {
    font-size: 22px;
    font-weight: 600;
  }
  .icon_waring {
    color: #faad14;
    font-size: 22px;
    font-weight: 600;
    margin-right: 10px;
  }
  .confirm_tt {
    display: block;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .name {
    font-size: 16px;
    margin-bottom: 0;
  }
  .url {
    font-size: 16px;
  }
}
</style>
